import ChangeCircleIcon from '@mui/icons-material/ChangeCircle'
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt'
import TextSnippetIcon from '@mui/icons-material/TextSnippet'
import { Box, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { FormDataConsumer, TextInput, UpdateButton, useTranslate } from 'react-admin'
import { ObjectionOut, ObjectionStatus } from '../../../types/apiRequests'
import getLetterAdviceText from '../../../utils/getLetterAdviceTexts'

const notReady: number[] = []

const DecisionEditStep: React.FC = () => {
  const translate = useTranslate()

  const checkIfLetterCanBeGenerated = (data: ObjectionOut): string | undefined => {
    if (!data.decision) return 'resources.objections.warnings.noDecision'
    if (!data.established_value) return 'resources.objections.warnings.noEstablishedValue'

    for (const grief of data.objection_grievances || []) {
      if (grief.decision_text_number === null || grief.decision_text_number === undefined)
        return 'resources.objections.warnings.noGriefDecisionTexts'
    }

    return undefined
  }

  const validateVDecisionText = (value: string) => {
    if (value && value.includes('#[')) {
      return 'De tekst bevat nog placeholders.'
    }
    return undefined
  }

  return (
    <>
      <Typography variant="subtitle1">
        {translate('resources.objections.decisionLetter')}
      </Typography>

      <FormDataConsumer>
        {({ formData }) => {
          const recordProps = formData as unknown as ObjectionOut
          const letterTexts = getLetterAdviceText(recordProps)
          const warnings = checkIfLetterCanBeGenerated(recordProps)
          const translation = warnings || 'resources.objections.createLetter'

          return (!letterTexts || letterTexts?.length === 0) && (
            <Box mb={8}>
              <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>
                {translate(translation)}
              </Typography>
              <UpdateButton
                label="Genereer brief teksten"
                data={{ ...formData, createLetter: true }}
                variant="contained"
                icon={<TextSnippetIcon />}
                disabled={!!warnings}
                sx={{ mt: 2 }}
              />
            </Box>
          )
        }}
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData }) => {
          const recordProps = formData as unknown as ObjectionOut
          const objectionGrievances = recordProps.objection_grievances || []
          const letterTexts = getLetterAdviceText(recordProps)
          const disabled = recordProps.status !== ObjectionStatus.UNDER_REVIEW

          return (letterTexts?.length > 0 && objectionGrievances?.length > 0) && (
            <Box display="flex" flexDirection="column" mt={4}>
              <Box display="flex" flexDirection="row" mb={1}>
                <Typography variant="body1" sx={{ fontWeight: 'bold', width: '40%' }}>
                  {translate('resources.objections.citaat')}
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: 'bold', width: '55%' }}>
                  {translate('resources.objections.letterText')}
                </Typography>
                <Box sx={{ width: '5%' }} />
              </Box>
              {objectionGrievances.map((og, index) => {
                const containsHashtag = og.letter_advice_text?.includes('#[')
                const isReady = og.ready
                let isChanged = false

                if (!isReady && !notReady.includes(index)) {
                  notReady.push(index)
                } else if (isReady && notReady.includes(index)) {
                  isChanged = true
                }

                const getHelperText = () => {
                  if (!isReady) return 'resources.objections.notReady'
                  if (containsHashtag) return 'resources.objections.hasHashtagInstructions'
                  if (isChanged) return 'resources.objections.hasChangedInstructions'
                  return false
                }

                const getBackgroundColor = () => {
                  if (!isReady) return '#f8d3d1'
                  if (containsHashtag) return '#f8d3d1'
                  if (isChanged) return '#f1f1f3'
                  return 'transparant'
                }

                return (
                  <Box
                    display="flex"
                    flexDirection="row"
                    key={index}
                    mb={1}
                    borderRadius="10px"
                    padding="10px"
                    bgcolor={getBackgroundColor()}
                  >
                    <Typography variant="body1" sx={{ fontSize: '0.8rem', width: '40%' }}>
                      {og.citaat}
                    </Typography>
                    <Box ml={2} sx={{ width: '55%' }}>
                      <TextInput
                        source={`objection_grievances[${index}].letter_advice_text`}
                        label="resources.objections.decisionAdviceText"
                        defaultValue={og.letter_advice_text}
                        validate={validateVDecisionText}
                        minRows={3}
                        InputProps={{
                          sx: { backgroundColor: '#fff' },
                          disabled,
                        }}
                        helperText={getHelperText()}
                        multiline
                        fullWidth
                      />
                    </Box>
                    { containsHashtag && <Tooltip title={translate('resources.objections.hasHashtag')}>
                      <ElectricBoltIcon sx={{ width: '5%' }} />
                    </Tooltip>
                    }
                    { isChanged && !containsHashtag && <Tooltip title={translate('resources.objections.hasChanged')}>
                      <ChangeCircleIcon sx={{ width: '5%' }} />
                    </Tooltip>
                    }
                  </Box>
                )})}
            </Box>
          )
        }}
      </FormDataConsumer>
    </>
  )
}

export default DecisionEditStep
