import { Resource } from 'react-admin'
import { debug } from '../test'
import { Permissions } from '../types/apiTypes'
import courts from './Courts'
import grievances from './Grievances'
import municipalities from './Municipalities'
import objections from './Objections'

const availableResources = ({scopes}: Permissions): JSX.Element[] => [
  <Resource
    key="objections"
    name="objection"
    options={{
      label: 'Bezwaren',
      scopes: ['objections:all']
    }}
    list={objections.list}
    edit={objections.edit}
    show={objections.show}
    create={objections.create}
    icon={objections.icon}
  />,
  <Resource
    key="grievances"
    name="grievances"
    options={{
      label: 'Grieven',
      scopes: ['objections:all']
    }}
    list={grievances.list}
    edit={grievances.edit}
    create={grievances.create}
    icon={grievances.icon}
  />,
  <Resource
    key="municipalities"
    name="municipality"
    options={{
      label: 'Gemeenten',
      scopes: ['objections:all']
    }}
    list={municipalities.list}
    edit={municipalities.edit}
    create={municipalities.create}
    icon={municipalities.icon}
  />,
  <Resource
    key="courts"
    name="courts"
    options={{
      label: 'Rechtbanken',
      scopes: ['objections:all']
    }}
    list={courts.list}
    edit={courts.edit}
    create={courts.create}
    icon={courts.icon}
  />
]

const fallback = (): JSX.Element[] => [
  <Resource
    key="objections"
    name="objection"
    options={{
      label: 'Bezwaren',
      scopes: ['objections:all']
    }}
    list={objections.list}
    edit={objections.edit}
    create={objections.create}
    icon={objections.icon}
  />
]

export const resourcesForScopes = ({ scopes }: Permissions): JSX.Element[] => {
  debug && console.log({scopes})
  if (!scopes || scopes.length === 0) {
    console.warn('No scopes received, returning fallback resource')
    return fallback()
  }
  const resources = availableResources({ scopes })
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    .filter(resource => resource.props.options.scopes.find(scope => scopes.includes(scope)) !== undefined)
  if (!resources || resources.length === 0) {
    console.warn('No resources received, returning fallback resource')
    return fallback()
  }
  return resources
}
