import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import { FC, useState } from 'react'
import {
  Button,
  EditBase,
  FileField,
  FileInput,
  SaveButton,
  SimpleForm,
  Toolbar,
  useNotify,
  useRecordContext, useTranslate
} from 'react-admin'
import { ObjectionOut } from '../../types/apiRequests'


const SaveToolbar = props => (
  <Toolbar {...props} >
    <SaveButton alwaysEnable />
  </Toolbar>
)
const UploadDocumentButton: FC = () => {
  const [open, setOpen] = useState(false)
  const translate = useTranslate()
  const notify = useNotify()
  const record = useRecordContext<ObjectionOut>()

  const redirect = (resource, id, _data) => {
    setOpen(false)
    notify(translate('resources.objections.upload.success'), { type: 'info' })
    return `${resource}/${id}/show`
  }

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        variant="contained"
        label={translate('resources.objections.upload.button')}
      />
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="sm">
        <DialogTitle>{translate('resources.objections.upload.title', { id: record?.objection_number })}</DialogTitle>
        <DialogContent>
          <EditBase
            resource="objection"
            id={record?.id}
            redirect={redirect}
            mutationMode="pessimistic"
          >
            <SimpleForm toolbar={<SaveToolbar />}>
              <FileInput
                source="files"
                label={translate('resources.objections.properties.uploadExtraDocuments')}
                accept={{ 'application/pdf': ['.pdf'] }}
                placeholder={translate('resources.objections.properties.uploadExtraDocumentsPlaceholders')}
                multiple
              >
                <FileField source="src" title="title" />
              </FileInput>
            </SimpleForm>
          </EditBase>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default UploadDocumentButton
