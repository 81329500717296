/* eslint-disable camelcase */
import ErrorIcon from '@mui/icons-material/Error'
import ReplayIcon from '@mui/icons-material/Replay'
import { Box, CircularProgress, Typography } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { Button, DeleteButton, useTranslate } from 'react-admin'
import { ObjectionOut, ObjectionStatus } from '../../types/apiRequests'

interface Props {
  objection: ObjectionOut;
  onRetry: () => void;
}

const LoadingObjection: FC<Props> = ({ objection, onRetry }) => {
  const translate = useTranslate()
  const [elapsedTime, setElapsedTime] = useState(0)
  const [showError, setShowError] = useState(false)

  const { created, status, objection_grievances } = objection

  useEffect(() => {
    const createdTime = new Date(created).getTime()

    const interval = setInterval(() => {
      const currentTime = Date.now()
      const secondsElapsed = Math.floor((currentTime - createdTime) / 1000)
      setElapsedTime(secondsElapsed)

      if (secondsElapsed >= 180 && status === ObjectionStatus.RECEIVED) {
        setShowError(true)
        clearInterval(interval)
      }
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [created, status])

  const getMessage = () => {
    if (showError) {
      return 'resources.objections.loading.error'
    }
    if (elapsedTime <= 5) {
      return 'resources.objections.loading.uploadingFile'
    }
    if (elapsedTime <= 20) {
      return 'resources.objections.loading.textFromDoc'
    }
    if (elapsedTime >= 60 && (!objection_grievances || objection_grievances.length === 0)) {
      return 'resources.objections.loading.takesLonger'
    }
    return 'resources.objections.loading.propertyFromObjection'
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="center" gap={2} mt={2}>
      {showError ? (
        <>
          <ErrorIcon color={'error'} sx={{ fontSize: 40 }} />
          <Typography color={'error'} variant="body1" sx={{ textAlign: 'center'}}>
            {translate(getMessage())}
          </Typography>
          {
            // TODO: Dit moet nog gemaakt worden. Retry zou het document opnieuw moeten verwerken.
          }
          <Box display="flex" flexDirection="row" gap={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={onRetry}
              label={translate('data.retry')}
            >
              <ReplayIcon />
            </Button>
            <DeleteButton mutationMode="undoable" />
          </Box>
        </>
      ) : (
        <>
          <CircularProgress />
          <Typography variant="body1">{translate(getMessage())}</Typography>
        </>
      )}
    </Box>
  )
}

export default LoadingObjection
